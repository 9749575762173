import React from 'react';
function Divider() {
  return (
    <section className="divider-banner">

      <div className="row justify-content-center text-center">
        <div className="left-svg">
          <svg baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="172" height="55" viewBox="0 0 172 55" overflow="inherit" xmlSpace="preserve">
            <g>
              <path fill="none" stroke="#DDFFE7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M125.815,15.703c0-2.23,3.127-14.462,20.023-14.462c19.75,0,24.97,19.541,24.97,26.143c0,7.52-2.836,26.375-25.336,26.375  s-40.78-39.263-79.5-39.263c-23.613,0-32.28,38.911-49.905,38.911c-9.75,0-14.875-5.625-14.875-11.875    c0-6.625,4.438-11.125,11.563-11.125c6.592,0,9.562,4.75,9.562,9.25" data-length="319.318115234375"></path>
              <path fill="none" stroke="#DDFFE7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M71.942,30.408c-8.251,0-11.417,4.812-11.417,10.875c0,5.125,4.349,12.368,14.349,12.368s17-7.99,17-15.125   c0-19.952-18.599-24.031-25.901-24.031" data-length="103.72833251953125"></path>
            </g>
            <desc>Left Side</desc><defs></defs>
          </svg>
        </div>
        {/* <div className="line"></div> */}
        <svg className="d-none d-sm-block" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="79" height="27" viewBox="0 0 78.2 26.4" enableBackground="new 0 0 78.2 26.4" xmlSpace="preserve">
          <g>
            <path d="M57.3,13.1c-3.2,10.4,10.4,16.1,16.8,8.7c7.1-8.2,0.6-17.8-7-20.1c-19.6-5.2-31.9,18-49,23.1C9.3,27.5-1.7,20.4,1.6,9.8   c3.8-12.4,23.3-9,19.3,4" fill="none" stroke="#d94362" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"></path>
          </g>
        </svg>
        <div>
          <svg baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="172" height="55" viewBox="0 0 172 55" overflow="inherit" xmlSpace="preserve">
            <g>
              <path fill="none" stroke="#DDFFE7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M125.815,15.703c0-2.23,3.127-14.462,20.023-14.462c19.75,0,24.97,19.541,24.97,26.143c0,7.52-2.836,26.375-25.336,26.375    s-40.78-39.263-79.5-39.263c-23.613,0-32.28,38.911-49.905,38.911c-9.75,0-14.875-5.625-14.875-11.875    c0-6.625,4.438-11.125,11.563-11.125c6.592,0,9.562,4.75,9.562,9.25" data-length="319.318115234375"></path>
              <path fill="none" stroke="#DDFFE7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M71.942,30.408c-8.251,0-11.417,4.812-11.417,10.875c0,5.125,4.349,12.368,14.349,12.368s17-7.99,17-15.125    c0-19.952-18.599-24.031-25.901-24.031" data-length="103.72833251953125"></path>
            </g>
            <desc>Right Side</desc><defs></defs>
          </svg>
        </div>
      </div>
      {/* <svg width="640" height="250" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path id="path2383" d="m130.424789,192.484528c5.347214,-7.567429 3.672729,-18.679031 -0.897858,-21.884766c-8.063118,-5.856277 -16.876259,6.366287 -12.837143,18.526962c4.031113,12.517319 14.122147,21.267746 27.859741,23.769913c29.803345,5.265564 88.753922,-27.178055 126.139771,-37.105835c27.772552,-7.374985 44.737732,3.70697 53.891937,15.980652c-18.814636,-13.327133 -35.962769,-8.691956 -53.610626,-5.4198c-40.492233,7.507782 -82.376175,39.384064 -126.758072,34.370102c-20.720802,-3.09549 -35.239151,-23.671143 -34.04528,-39.805344c0.106049,-1.433762 0.336189,-2.832489 0.697144,-4.180801c2.727554,-9.561676 7.519974,-13.483307 11.765518,-14.646454c11.540581,-3.161896 22.972786,17.871918 7.794868,30.39537z" strokeWidth="1px" fillRule="evenodd" fill="#000000" />
          <path id="path2479" d="m487.119385,189.199921c-5.671265,7.631012 -3.895264,18.836304 0.952271,22.069031c8.551758,5.905624 17.89917,-6.419983 13.615234,-18.682968c-4.275269,-12.622757 -14.978088,-21.446869 -29.548309,-23.969986c-31.609894,-5.309998 -94.133331,27.406815 -133.785309,37.418243c-29.45575,7.437042 -47.449219,-3.73822 -57.158203,-16.115265c19.954956,13.439377 38.142334,8.765167 56.859802,5.465454c42.946655,-7.570999 87.369202,-39.715729 134.441101,-34.659546c21.976685,3.121552 37.375,23.870499 36.108826,40.140549c-0.112488,1.445938 -0.356628,2.856339 -0.739441,4.216019c-2.892883,9.642197 -7.975769,13.596756 -12.478638,14.769791c-12.240051,3.188507 -24.365143,-18.0224 -8.267334,-30.651321z" strokeWidth="1px" fillRule="evenodd" fill="#000000" />
        </g>
      </svg> */}
    </section>
  );
}

export default Divider;
